import React from "react";

import styles from "./index.module.css";

export const Footer = () => {
  return (
    <div className={styles.footerWrapper}>
      <p>Copyright catchon labs 2022</p>
    </div>
  );
};
